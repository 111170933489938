import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import { useEffect } from 'react';
import { useLocation } from '@reach/router';


import { StateProvider, } from "../state/state"
import Header from "./Header"
import Partners from "./Partners"
import Footer from "./Footer"
import ExitRamp from "./ExitRamp"

import MedicalConditionSchema from "./MedicalConditionSchema"
import MedicalWebPageSchema from "./MedicalWebPageSchema"
import './RobotoFont.css';
import './Onetrust.css';

/*
Necessary so FA icons don't load in huge then resize
https://stackoverflow.com/questions/49781726/react-font-awesome-renders-big-icon-until-scales-down
*/
// config.autoAddCss = false

const Layout = (props) => {
  const initialState = {
    isExitRampOpen: false,
    externalUrl: "",
    stagesTab: 'allVideos',
    headerTab: '',
    cookieVisible: true,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "toggleExitRamp":
        return {
          ...state,
          isExitRampOpen: action.isExitRampOpen,
          externalUrl: action.externalUrl,
        }
      case "handleStagesTab":
        return {
          ...state,
          stagesTab: action.stagesTab,
        }
      case "handleHeaderTab":
        return {
          ...state,
          headerTab: action.headerTab,
        }
      case "handleCookieVisible":
        return {
          ...state,
          cookieVisible: action.cookieVisible,
        }

      default:
        return state
    }
  }
  const {
    title,
    description,
    canonicalURL,
    OGTitle,
    OGDescription,
    OGImage,
    OGUrl,
    OGAppID,
    OGType,
    OGSiteName,
    schema,
  } = props;

  const host = useLocation().hostname;

  useEffect(() => {
    if (host === "hodgkinhub.com" || host === "www.hodgkinhub.com") {
      const script = document.createElement('script');
      script.src = 'https://assets.adobedtm.com/22baa8e94be8/8f6ceea702e1/launch-54c02fdeb470.min.js';
      script.async = true;
      document.head.appendChild(script);
    } else {
      const script = document.createElement('script');
      script.src = 'https://assets.adobedtm.com/22baa8e94be8/8f6ceea702e1/launch-d061534aa9e9-staging.min.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [host]);

  useEffect(() => {

    //begin onetrust banner modification scripts
    const targetSpot = document.getElementsByTagName('header')[0];
    let otBanner = null;
    let bannerHeightMonitor = 0;
    targetSpot.style.paddingTop = 0;

    //make space in header for fixed-position banner to reside
    const adjustLayoutForBanner = (el) => {
      bannerHeightMonitor = el.offsetHeight;
      targetSpot.style.paddingTop = bannerHeightMonitor + 'px';
    }

    //listen for changes in size or visibility to banner
    const dimensionsObserver = new ResizeObserver(function (entries) {
      const rect = entries[0].contentRect;
      const width = rect.width;
      const height = rect.height;
      if (width === 0 || height === 0) {
        //banner has been dismissed
        targetSpot.style.paddingTop = 0;
      }
      if (height != bannerHeightMonitor) {
        //banner and viewport have been resized
        adjustLayoutForBanner(otBanner);
      }
    });

    //listen for banner to be written to page
    const additionObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          Array.from(mutation.addedNodes).forEach((node) => {
            if (node.id === 'onetrust-consent-sdk') {
              otBanner = node.querySelector('#onetrust-banner-sdk');
              dimensionsObserver.observe(otBanner);
              additionObserver.disconnect();
              window.scrollTo(0, 0);
            }
          });
        }
      });
    });
    const observerConfig = {
      childList: true,
      attributes: true,
      characterData: false,
      subtree: false,
    };
    additionObserver.observe(document.body, observerConfig);
    //end onetrust banner modification scripts

  }, []);


  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={OGTitle} />
        <meta property="og:description" content={OGDescription} />
        <meta property="og:image" content={OGImage} />
        <meta property="og:url" content={OGUrl} />
        <meta property="fb:app_id" content={OGAppID} />
        <meta property="og:type" content={OGType} />
        <meta property="og:site_name" content={OGSiteName} />
        <link rel='canonical' href={canonicalURL} />
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      <MedicalConditionSchema />
      <MedicalWebPageSchema />
      <section>

        <Global
          styles={css`
            /* reset */
            * {
              font-family: "Roboto", sans-serif;
              color: #8c8d91;
            }
          `}
        />
        <Header />
        <main>{props.children}</main>

        <Partners />
        <Footer />
        <ExitRamp />
      </section>
    </StateProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
